import React from 'react';
import Header from '../Partials/Header';

const Kalasinfo = () => {
	const kalasstart = '14.00';
	const middagstid = '17.00';
	const OSA = '17 feb';
	return (
		<div className="wholeScreen">
			<Header />
			<div className="kalasInfoBox">
				<h3 className="kalasHL">Dags för kalas</h3>
				<p className="kalasText">
					Farbror fyller aktningsvärda 40 år gammal och det är således dags för
					lite firande. Och har jag inte kalas när jag fyller gubbe, när ska jag
					då ha det? Och hela poängen är ju att få fira det med alla er jag gillar.
				</p>
				<p className="kalasText">
					Den 24e februari kalasas det och vi har bokat en lokal tio minuters
					promenad från hemmet, så gott om plats blir det. Det skulle göra mig
					väldigt glad om du vill förära mig med ditt sällskap. Och Ja!! Jag
					gillar presenter, så jag blir inte ledsen om jag får en. Vi öppnar
					dörrarna klockan {kalasstart}, man får komma när man vill men
					hamburger-buffén serveras klockan {middagstid}. OSA till mig eller Lucia senast 17 feb.
				</p>
				<p className="kalasText sammanfattnig">
					För att sammanfatta:
					<br />
					När: 24 feb
					<br />
					Var: Norra Ullevi 1 (karta finns på länken där uppe)
					<br />
					Klockan: från kl {kalasstart}
					<br />
					Mat: Hamburgar-buffé klockan {middagstid}
					<br />
					OSA: {OSA}
				</p>
				<p className="kalasWelcome">Hjärtligt Välkommen</p>
			</div>
		</div>
	);
};

export default Kalasinfo;
