import { Routes, Route } from 'react-router-dom';
import './App.css';
import Index from './Components/Views/Index';
import Welcome from './Components/Views/Welcome';
import Kalasinfo from './Components/Views/Kalasinfo';
import Location from './Components/Views/Location';

function App() {
  return (
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/Kalasinbjudan' element={<Welcome />} />
        <Route path='/Kalasinfo' element={<Kalasinfo />} />
        <Route path='/Location' element={<Location />} />
      </Routes>
  );
}

export default App;
