import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Korv from '../../Assets/Images/huvud.png'
import InfoButton from '../../Assets/Images/Buttons/InfoButton.png'
import MapButton from '../../Assets/Images/Buttons/FindPartyButton.png'

const Header = () => {
  
  return (
		<header>
			<div className="container">
				<div className="huvudBox">
					<Link to="/Kalasinbjudan"><img id="korvHuvud" src={Korv} alt='Marcus eating a big and equally tasty sausage' /></Link>
				</div>
				<div className="linkBox">
					<nav className="myLinks">
						<NavLink className="link" to="/Kalasinfo"><img className='linkButton' src={InfoButton} alt='Kalasinformation' /></NavLink>
						<NavLink className="link" to="/Location"><img className='linkButton' src={MapButton} alt='Hitta till kalaset' /></NavLink>
					</nav>
				</div>
			</div>
		</header>
	);
}

export default Header
