import React from 'react';
import Header from '../Partials/Header';
const Location = () => {
	return (
		<div>
			<Header />
			<div className="hittaHitBox">
				<div className="kalasInfoBox kalasInfoExtra">
					Vi har hyrt en föreningslokal, Norra Ullevi 1. Det går inte att köra
					bil ändå fram till lokalen men gatorna runtom som har lokalen verkar
					ha gott om plats att parkera på. Hör av dig om det är något du
					funderar på.
				</div>
				<div className="kartBox">
					<iframe
						className="kartan"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2020.1224534306784!2d17.52039915510647!3d59.581023107948745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465fae3007ddfe91%3A0xb071ef8046ba28eb!2sNorra%20ULLEVI%201%2C%20746%2051%20B%C3%A5lsta!5e0!3m2!1sen!2sse!4v1704583621688!5m2!1sen!2sse"
						width="600"
						height="450"
						style={{ border: 0 }}
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe>
				</div>
			</div>
		</div>
	);
};

export default Location;
