import React from 'react'
import Navbar from '../Partials/Header'

const Welcome = () => {
  return (
		<div className='wholeScreen'>
			<Navbar />
			<div className='welcomeText'>
				<p className="titleText">Moonlits 40 årskalas</p>
			</div>
		</div>
	);
}

export default Welcome