import React from 'react'
import Korv from '../../Assets/Images/huvud.png'
import { Link } from 'react-router-dom'

const Index = () => {
  return (
    <div className='indexContainer'>
        <div className="linkbox">
            <nav className='headLink'>
                <Link className='indexLink' to="/Kalasinbjudan"><img id="indexHuvud"  src={Korv} alt='Marcus eating a big and equally tasty sausage' /></Link>
            </nav>
        </div>
    </div>
  )
}

export default Index
